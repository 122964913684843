import { SetStateAction } from 'react'

import debounce from 'debounce'
import { Select } from 'flowbite-react'

import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { useEditarEncomendaContext } from '@/contexts/editar-encomenda.context'
import { ENCOMENDAS_TABLE_HEADERS } from '@/pages/Encomendas/constants'

const EditarEncomendaItensGrid = () => {
  const {
    itensLogistica,
    isFetchingItens,
    setPage,
    pageSize,
    setPageSize,
    totalPages,
    setSearch
  } = useEditarEncomendaContext()

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)


  return (
    <div className='col-span-12 overflow-hidden mb-10'>
      <SearchTableItem isAddItem={false} setFilterText={e => handleSearch(e)} />
      <TableItem
        isActions={false}
        isRowClicable={false}
        data={itensLogistica?.items ?? []}
        headers={ENCOMENDAS_TABLE_HEADERS}
        deleteAction={() => void 0}
        idKey='orderNumber'
        isIdle={isFetchingItens}
      />
      <PaginationItem
        totalItems={itensLogistica?.totalItems ?? (0 as number)}
        pageSize={pageSize}
        totalPages={totalPages}
        onPageChange={(pageParam: number) => setPage(pageParam)}
        forceDisplay={true}
      >
        <Select
          id='select-page-size'
          data-testid='select-page-size'
          defaultValue={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          <option value='10'>10</option>
          <option value='25'>25</option>
          <option value='50'>50</option>
          <option value='100'>100</option>
        </Select>
      </PaginationItem>
    </div>
  )
}
export default EditarEncomendaItensGrid
