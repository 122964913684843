import { useEffect, useMemo } from 'react'

import BreadCrumbs from '@/components/Breadcrumbs/breadcrumbs.component'
import EditarEncomendaItensGrid from '@/components/Encomendas/editar-encomenda/editar-encomenda-grid.component'
import ExtravioEncomendaModal from '@/components/Encomendas/extravio-encomenda.modal'
import Loader from '@/components/Loader'
import TimeLineItem from '@/components/Table/Logistica/timeline-item.component'
import { Skeleton } from '@/components/ui/skeleton'
import { useEditarEncomendaContext } from '@/contexts/editar-encomenda.context'
import { formatDateBrazilianString } from '@/utils/date-format-brazilian-time'

const EditarEncomendaContainer = () => {
  const { itemLogistica, isLoadingItem, isLoadingItens,setOpenModal,openModal } =
    useEditarEncomendaContext()

  const links = [
    {
      title: 'Logística',
      url: `/logistica`
    },
    {
      title: 'Acompanhamento',
      url: ``
    }
  ]

  const pedidosSteps = [
    {
      title: 'Pedido realizado',
      icon: '/icons/pedido-realizado.svg'
    },
    {
      title: 'Produto coletado',
      icon: '/icons/produto-coletado.svg'
    },
    {
      title: 'Preparando envio',
      icon: '/icons/preparando-envio.svg'
    },
    {
      title: 'Em transferência para o destino',
      icon: '/icons/em-transferencia.svg'
    },
    {
      title: 'Saiu para entrega ao destinatário',
      icon: '/icons/saiu-entrega.svg'
    },
    {
      title: 'Entrega concluída',
      icon: '/icons/entrega-concluida.svg'
    }
  ]

  const timeline = useMemo(() => {
    return itemLogistica?.timeLineItens?.map((item, index) => ({
      id: index,
      title: pedidosSteps[index].title,
      date: formatDateBrazilianString(item.eventDate),
      icon: pedidosSteps[index].icon,
      finished: item.finished,
      description: item.description
    }))
  }, [itemLogistica?.timeLineItens])

  useEffect(() => {
    if (itemLogistica?.deliveryFailed) {
      setOpenModal(true)
    }
  }, [itemLogistica])

  return (
    <>
      {isLoadingItens && <Loader key={1} size='2xl' data-testid='loader' />}
      <div className='min-h-screen'>
        <div className='grid grid-col-span-12 gap-5'>
          <div className='w-full flex col-span-12'>
            <BreadCrumbs links={links} />
          </div>
          <div className='bg-white p-5 rounded-lg col-span-12 flex justify-between items-center'>
            <p className='font-semibold text-sm'>
              Encomenda | {itemLogistica?.deliveryGroupNumber}
            </p>
            {isLoadingItem ? (
              <Skeleton className='h-4 w-[250px]' data-testid='skeleton' />
            ) : (
              <p className='font-normal italic text-sm'>
                Última atualização em:{' '}
                {formatDateBrazilianString(itemLogistica?.lastUpdate as string)}
              </p>
            )}
          </div>
          <div className='bg-white p-3 rounded-lg col-span-12'>
            <p className='font-semibold text-lg leading-7'>
              Informações gerais
            </p>

            <div className='grid grid-cols-1 md:grid-cols-12 gap-5 pt-5'>
              <div className='col-span-6 md:col-span-2 flex flex-col'>
                <span className='text-xs text-primary-hover'>
                  Total de itens
                </span>
                <div className='font-bold text-xl'>
                  {isLoadingItem ? (
                    <Skeleton className='h-6 w-[50px]' data-testid='skeleton' />
                  ) : (
                    itemLogistica?.itemsCount
                  )}
                </div>
              </div>

              <div className='col-span-6 md:col-span-6 flex flex-col'>
                <span className='text-xs text-primary-hover'>
                  Endereço de entrega
                </span>
                <div className='font-bold text-xl'>
                  {isLoadingItem ? (
                    <Skeleton
                      className='h-6 w-[200px]'
                      data-testid='skeleton'
                    />
                  ) : (
                    itemLogistica?.address
                  )}
                </div>
              </div>

              <div className='col-span-6 md:col-span-2 flex flex-col'>
                <span className='text-xs text-primary-hover'>
                  Previsão de entrega
                </span>
                <div className='font-bold text-xl'>
                  {isLoadingItem ? (
                    <Skeleton
                      className='h-6 w-[200px]'
                      data-testid='skeleton'
                    />
                  ) : (
                    itemLogistica?.expectedDate
                  )}
                </div>
              </div>

              <div className='col-span-6 md:col-span-2 flex flex-col items-start'>
                <span className='text-xs text-primary-hover'>
                  Baixar comprovante
                </span>
                <div className='flex items-center space-x-2'>
                  <a
                    href={itemLogistica?.urlProofDelivery || '#'}
                    download
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex items-center px-1 py-0 rounded-xl'
                    title={
                      itemLogistica?.urlProofDelivery
                        ? ''
                        : 'Comprovante indisponível'
                    }
                    style={{
                      backgroundColor: itemLogistica?.urlProofDelivery
                        ? 'rgb(3, 77, 46)'
                        : 'rgb(156, 163, 175)',
                      pointerEvents: itemLogistica?.urlProofDelivery
                        ? 'auto'
                        : 'none'
                    }}
                  >
                    <img
                      src='/icons/baixar-comprovante.svg'
                      alt='Download'
                      className='w-4 h-6'
                    />
                  </a>
                  <p className='font-bold text-xl'>Entrega</p>
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-12 bg-white rounded-lg pt-10 pb-5'>
            <div className='grid grid-cols-12 gap-5 w-full overflow-hidden px-3'>
              {isLoadingItem
                ? Array(6)
                    .fill('')
                    .map((_, index) => (
                      <div
                        key={index}
                        className='relative lg:col-span-2 col-span-12 flex justify-start items-center flex-col gap-1'
                      >
                        <Skeleton
                          className='w-[96px] h-[96px] rounded-full'
                          data-testid='skeleton'
                        />
                        <Skeleton
                          className='w-[100px] h-[6px]'
                          data-testid='skeleton'
                        />
                        <Skeleton
                          className='w-[80px] h-[6px]'
                          data-testid='skeleton'
                        />
                      </div>
                    ))
                : timeline?.map((item, index) => (
                    <TimeLineItem
                      key={index}
                      {...item}
                      deliveryFailed={itemLogistica?.deliveryFailed}
                    />
                  ))}
            </div>
          </div>
          <EditarEncomendaItensGrid />
        </div>
      </div>
      <ExtravioEncomendaModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  )
}

export default EditarEncomendaContainer
