export const shouldOpenOnboarding = (
  pedidosList: any,
  isOnboardingFinished: boolean
) => {
  if (!isOnboardingFinished) {
    if (
      (typeof pedidosList === 'object' &&
        Object.keys(pedidosList).length > 0) ||
      typeof pedidosList === 'string'
    ) {
      return true
    }
  }
  return false
}
