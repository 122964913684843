import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'

import { routeNames } from '@/configs/routePaths'
import { useUserContext } from '@/contexts/user.context'

const navigation = [
  { name: 'Painel', href: '/dashboard', current: true },
  {
    name: 'Cadastros',
    href: `/EmployeeBaseV3/Index`,
    current: false,
    permissao: 169
  },
  {
    name: 'Pedidos',
    href: `/${routeNames.pedidos}`,
    current: false,
    permissao: 169
  },
  {
    name: 'Importação',
    href: '/ToolboxV3/Index',
    current: false,
    permissao: 169
  },
  { name: 'Logística', href: `/${routeNames.encomendas}`, current: false },
  {
    name: 'Regras de Operadoras',
    href: '/KnowledgeBaseV3/Index',
    current: false,
    permissao: 169
  },
  { name: 'Solicitações', href: '/Services/Index', current: false },
  {
    name: 'Melhor Rota',
    href: '/RoutingV3/Index?isVrPortal=True',
    current: false,
    permissao: 169
  },
  {
    name: 'Relatórios',
    href: '/ReportsV3/Index',
    current: false,
    permissao: 170
  }
]

export default function NavBar() {
  const { isAllowedV2Pedidos, userPermissions } = useUserContext()
  const location = useLocation()

  const handleItemHref = (href: string) => {
    if (href === `/${routeNames.pedidos}`) {
      return !isAllowedV2Pedidos ? routeNames.pedidosOld : href
    }
    return href
  }

  const isActive = (href: string) => {
    return location.pathname === handleItemHref(href)
  }

  const hasPermission = (permissao?: number) => {
    return (
      permissao === undefined ||
      userPermissions.some(permission => permission.code === permissao)
    )
  }

  return (
    <Disclosure as='nav' className='bg-quintenary'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-full flex md:justify-center px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className='block h-6 w-6 text-primary-dark'
                      aria-hidden='true'
                    />
                  ) : (
                    <Bars3Icon
                      className='block h-6 w-6 text-primary-dark'
                      aria-hidden='true'
                    />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {navigation.map(
                      item =>
                        hasPermission(item.permissao) && (
                          <Link
                            key={item.name}
                            to={handleItemHref(item.href)}
                            id={item.name.toLowerCase().replace(' ', '-')}
                            className={classNames(
                              isActive(item.href)
                                ? 'bg-[#00be28] text-white'
                                : 'text-gray-900 hover:bg-quaternary hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-semibold transition-all'
                            )}
                            aria-current={
                              isActive(item.href) ? 'page' : undefined
                            }
                          >
                            {item.name}
                          </Link>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 px-2 pb-3 pt-2'>
              {navigation.map(
                item =>
                  hasPermission(item.permissao) && (
                    <Disclosure.Button
                      key={item.name}
                      as='a'
                      href={handleItemHref(item.href)}
                      className={classNames(
                        isActive(item.href)
                          ? 'bg-[#00be28] text-white'
                          : 'text-gray-900 hover:bg-gray-600 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={isActive(item.href) ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  )
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
