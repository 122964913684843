import { QueryFunctionContext } from 'react-query'

import { endPoints } from '@/configs/endPoints'
import {
  IAceiteGestaoInfoResultado,
  IContratoList
} from '@/interfaces/contrato'
import { API_PEDIDOS } from '@/utils/pedidos-fetcher'

export async function getContratoPorUnidade(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          idEmpregador: string
          idUnidades?: string[]
        }
      }
    ]
  >
): Promise<IContratoList> {
  const [, params] = ctx.queryKey
  const response = await API_PEDIDOS.post<IContratoList>(endPoints.contrato, {
    idEmpregador: params.params.idEmpregador,
    idUnidades: params.params.idUnidades
  })

  return response
}

export async function getAceiteGestao(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          idEmpregador: string
        }
      }
    ]
  >
): Promise<IAceiteGestaoInfoResultado> {
  const [, params] = ctx.queryKey
  const endpointAceite = endPoints.getContratoAceite.replace(
    '{id-empregador}',
    params.params.idEmpregador
  )
  const response = await API_PEDIDOS.get<IAceiteGestaoInfoResultado>(
    endpointAceite
  )
  return response
}
