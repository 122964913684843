import { useCallback } from 'react'

import { useLocation } from 'react-router-dom'

const useIsOnRoute = () => {
  const isOnRoute = useCallback(
    (path: string): boolean => {
      const location = useLocation()
      const result = location.pathname === path
      return result
    },
    [location.pathname]
  )

  return {
    isOnRoute
  }
}

export default useIsOnRoute
