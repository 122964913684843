import { Button, Modal } from 'flowbite-react'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

interface IExtravioEncomendaModalProps {
  onClose: () => void
  isOpen: boolean
}

const ExtravioEncomendaModal: React.FC<IExtravioEncomendaModalProps> = ({
  onClose,
  isOpen
}: IExtravioEncomendaModalProps) => {
  return (
    <Modal show={isOpen} size='md' onClose={onClose} popup>
      <Modal.Header />
      <Modal.Body>
        <div className='text-center'>
          <HiOutlineExclamationCircle className='mx-auto mb-4 h-24 w-24 text-red-600 dark:text-red-200' />
          <h3 className='mb-5 text-lg font-normal dark:text-white-400'>
            A encomenda sofreu um extravio! Será gerada uma nova coleta para os
            itens do pacote.
          </h3>
          <div className='flex justify-center gap-4'>
            <Button color='failure' onClick={onClose}>
              {'Ok'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default ExtravioEncomendaModal
