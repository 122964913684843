import { Dispatch, SetStateAction, useState } from 'react'

import { useParams } from 'react-router-dom'

import {
  useGetLogisticItem,
  useGetLogisticsList
} from '@/hooks/queries/use-get-logistics'
import { ILogisticItems, ILogisticsDetail } from '@/interfaces/logistics'
import { createContext } from '@/utils/create-context'

interface IEditarEncomendaContextProps {
  itemLogistica: ILogisticsDetail | undefined
  isLoadingItem: boolean
  itensLogistica: ILogisticItems | undefined
  isLoadingItens: boolean
  isFetchingItens: boolean
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  setPageSize: Dispatch<SetStateAction<number>>
  totalPages: number
  setSearch: Dispatch<SetStateAction<string>>
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>

}

const [useEditarEncomendaContext, EditarEncomendaProvider] =
  createContext<IEditarEncomendaContextProps>()

const EditarEncomendaContext = ({ children }: JSX.Element | any) => {
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState<number>(10)

  const { data: itemLogistica, isLoading: isLoadingItem } = useGetLogisticItem(
    Number(id) ?? 0
  )
  const [openModal, setOpenModal] = useState(false)

  const {
    data: itensLogistica,
    isLoading: isLoadingItens,
    isFetching: isFetchingItens
  } = useGetLogisticsList({
    page,
    pageSize,
    groupNumber: Number(id) ?? 0,
    search
  })
  const totalPages = Math.ceil(
    (itensLogistica?.totalItems ?? 0) / (pageSize ?? 1)
  )

  return (
    <EditarEncomendaProvider
      value={{
        itemLogistica,
        isLoadingItem,
        itensLogistica,
        isLoadingItens,
        isFetchingItens,
        setPage,
        pageSize,
        setPageSize,
        totalPages,
        setSearch,
        openModal,
        setOpenModal
      }}
    >
      {children}
    </EditarEncomendaProvider>
  )
}

export { EditarEncomendaContext, useEditarEncomendaContext }
