import { faker } from '@faker-js/faker'
import { UseQueryResult, useQuery } from 'react-query'

import { QueryKey } from '@/configs/queryKeys'
import { IDetalhePedido } from '@/interfaces/detalhe-pedido'
import { IGenericRequest } from '@/interfaces/generic'
import {
  IPedidoItem,
  IRevisaoPedido,
  IRevisaoPedidoOperadora,
  IPedidoDetalhe,
  IRepasse,
  ILancamento,
  IRevisaoEconomiaPedidoUnidade,
  IEdicaoPedidoItem,
  IConfirmar,
  IConfirmarPedidosProps,
  IVendaResultado,
  IPedidoPendenteResponse,
  IErrosPedidoResponse
} from '@/interfaces/pedidos'
import {
  getPedidos,
  getRevisaoPedidos,
  getRevisaoPedidosOperadora,
  getPedidosPendentes,
  getDetalhePedido,
  getPedidosListitensDetail,
  getRepasses,
  getLancamentos,
  getRevisaoEconomiaUnidade,
  getEdicaoPedidoItens,
  getVenda,
  getErrosPedido,
  getListaPedidos
} from '@/services/pedidos'

export function useGetPedidos({
  params
}: {
  params: {
    idEmpregador: string
    dataInicio: string
    dataFim: string
    inicio: number
    comprimento: number
    campo: string
    direcao: 'asc' | 'desc'
    procurar?: string
    situacoesPedidoVenda: string[]
    situacoesFinanceiras: string[]
    situacoesOperacionais: string[]
    idUnidades?: string[]
  }
}): UseQueryResult<IPedidoItem> {
  return useQuery([QueryKey.pedidosListItems, { params }], getPedidos, {
    keepPreviousData: true,
    retry: false,
    enabled: !!params.idEmpregador,
    initialData: {} as IPedidoItem
  })
}

export function useGetRevisaoPedidos({
  params
}: {
  params: {
    idEmpregador: string
    dataInicio: string
    dataFim: string
    inicio: number
    comprimento: number
    campo: string
    direcao: string
    idPedidos?: string[]
  }
}): UseQueryResult<IRevisaoPedido[]> {
  return useQuery(
    [QueryKey.pedidosRevisaoListItems, { params }],
    getRevisaoPedidos,
    {
      enabled: !!params.idEmpregador,
      retry: true,
      keepPreviousData: false,
      cacheTime: 0 // 1 hour
    }
  )
}

export function useGetConfirmarPedidos({
  params
}: {
  params: IConfirmarPedidosProps
}): UseQueryResult<IConfirmar> {
  return useQuery([QueryKey.confirmarPedidos, { params }], getListaPedidos, {
    keepPreviousData: false,
    retry: false,
    enabled: true
  })
}

export function useGetPedidoEdicaoItens({
  params
}: {
  params: {
    pedidoId: string
    vendaId: string
    inicio: number
    comprimento: number
    procurar?: string
    somenteErros: boolean
  }
}): UseQueryResult<IEdicaoPedidoItem[]> {
  return useQuery(
    [QueryKey.pedidoEdicaoItems, { params }],
    getEdicaoPedidoItens,
    {
      enabled: true
    }
  )
}

export function useGetRevisaoPedidosOperadora({
  params
}: {
  params: {
    pedidoId: string
  }
}): UseQueryResult<IRevisaoPedidoOperadora[]> {
  const generateRandomObject = () => ({
    pedidoId: faker.number.int({ min: 10000, max: 99999 }).toString(),
    operadoraId: faker.number.int({ min: 10000, max: 99999 }).toString(),
    vendaId: faker.number.int({ min: 10000, max: 99999 }).toString(),
    operadora: `Operadora ${faker.company.name()}`,
    tipoCartao: 'Cartão',
    qtdColaboradores: faker.number.int({ min: 1, max: 999 }).toString(),
    repasses: `R$ ${faker.finance.amount({
      min: 1000,
      max: 1000000,
      dec: 2,
      symbol: ''
    })}`,
    itens: `R$ ${faker.finance.amount({
      min: 1000,
      max: 1000000,
      dec: 2,
      symbol: ''
    })}`,
    sucesso: faker.datatype.boolean()
  })

  const randomObjectsArray = Array.from({ length: 15 }, generateRandomObject)
  return useQuery(
    [QueryKey.pedidosRevisaoOperadoras, { params }],
    getRevisaoPedidosOperadora,
    {
      keepPreviousData: false,
      retry: false,
      enabled: false,
      initialData: randomObjectsArray
    }
  )
}

export function useGetEconomiaUnidades({
  params
}: {
  params: {
    pedidoId: string
    previsaoDisponibilidade: any
  }
}): UseQueryResult<IRevisaoEconomiaPedidoUnidade[]> {
  const generateRandomObject = () => ({
    pedidoId: faker.number.int({ min: 10000, max: 99999 }).toString(),
    unidadeId: faker.number.int({ min: 10000, max: 99999 }).toString(),
    unidade: faker.company.name(),
    totalItens: `R$ ${faker.finance.amount({
      min: 1000,
      max: 1000000,
      dec: 2,
      symbol: ''
    })}`,
    totalEconomia: `R$ ${faker.finance.amount({
      min: 1000,
      max: 1000000,
      dec: 2,
      symbol: ''
    })}`,
    valor: `R$ ${faker.finance.amount({
      min: 1000,
      max: 1000000,
      dec: 2,
      symbol: ''
    })}`,
    usaEconomia: faker.datatype.boolean()
  })

  const randomObjectsArray = Array.from({ length: 5 }, generateRandomObject)
  return useQuery(
    [QueryKey.pedidosRevisaoEconomiaUnidades, { params }],
    getRevisaoEconomiaUnidade,
    {
      keepPreviousData: false,
      retry: false,
      enabled: false,
      initialData: randomObjectsArray
    }
  )
}

export function useGetPedidosPendentes({
  params
}: {
  params: {
    idEmpregador: string
    dataInicio: string
    dataFim: string
    inicio: number
    comprimento: number
    campo: string
    direcao: 'asc' | 'desc'
    procurar?: string
    situacoesPedidoVenda: string[]
    idUnidades?: string[]
    numeroPedido?: string | null
  }
}): UseQueryResult<IPedidoPendenteResponse> {
  if (params.situacoesPedidoVenda?.includes('Processado'))
    params.situacoesPedidoVenda.push('Alterado')
  return useQuery(
    [QueryKey.pedidosPendentesList, { params }],
    getPedidosPendentes,
    {
      keepPreviousData: true,
      retry: true,
      enabled:
        !!params.idEmpregador &&
        !!params.situacoesPedidoVenda &&
        !!params.idUnidades.length,
      initialData: {} as IPedidoPendenteResponse
    }
  )
}

export function useGetPedidoDetalhe({
  params
}: {
  params: {
    pedidoId: string
  }
}): UseQueryResult<IPedidoDetalhe> {
  const generateRandomObject = () => ({
    pedidoId: faker.string.uuid(),
    dtEmissao: faker.date.recent().toLocaleDateString('pt-BR'),
    dtPagamento: faker.date.recent().toLocaleDateString('pt-BR'),
    dtPrevisaoCarga: faker.date.recent().toLocaleDateString('pt-BR'),
    contatoName: faker.person.fullName(),
    situacaoBoleto: faker.finance.transactionType(),
    situacaoPedido: faker.commerce.productName(),
    canceladoPor: faker.person.fullName(),
    tipo: faker.commerce.productName(),
    periodoUtilizacao: `${faker.date
      .recent()
      .toLocaleDateString('pt-BR')} - ${faker.date
      .recent()
      .toLocaleDateString('pt-BR')}`
  })

  return useQuery([QueryKey.pedidoDetalhe, { params }], getDetalhePedido, {
    keepPreviousData: false,
    retry: false,
    enabled: true,
    initialData: generateRandomObject()
  })
}

export function useGetErrosPedido({
  params
}: {
  params: IGenericRequest
}): UseQueryResult<IErrosPedidoResponse> {
  return useQuery([QueryKey.errosPedido, { params }], getErrosPedido, {
    keepPreviousData: true,
    retry: false,
    enabled: params?.idsPedidos?.filter(id => id != null).length > 0
  })
}

export function useGetPedidoItemListDetail({
  pedidoId
}: {
  pedidoId: string
}): UseQueryResult<IDetalhePedido> {
  return useQuery(
    [QueryKey.pedidoItemListDetail, { pedidoId }],
    getPedidosListitensDetail,
    {
      keepPreviousData: false,
      retry: false,
      enabled: !!pedidoId
    }
  )
}

export function useGetRepasses({
  params
}: {
  params: {
    pedidoId: string
    vendaId?: string
    inicio: number
    comprimento: number
    procurar?: string
    tipo?: string
  }
}): UseQueryResult<IRepasse> {
  return useQuery([QueryKey.pedidoRepassesList, { params }], getRepasses, {
    enabled: !!params.pedidoId
  })
}

export function useGetLancamentos({
  params
}: {
  params: {
    pedidoId: string
    inicio: number
    comprimento: number
    procurar?: string
    tipoLancamentoFinanceiro: number
  }
}): UseQueryResult<ILancamento> {
  return useQuery(
    [QueryKey.pedidoLancamentosList, { params }],
    getLancamentos,
    {
      enabled: !!params.pedidoId
    }
  )
}

export function useGetVendas({
  params
}: {
  params: {
    pedidoId: string
    unidadeId: string
    operadoraId?: string
    vendaId?: string[]
    inicio: number
    comprimento: number
    procurar?: string
    situacoesItem?: string[]
    agrupar: boolean
    tipoVenda?: string
  }
}): UseQueryResult<IVendaResultado> {
  return useQuery([QueryKey.vendasList, { params }], getVenda, {
    enabled: !!params.pedidoId && !!params.unidadeId
  })
}
