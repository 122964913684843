export const OPTIONS = [
  { value: '1', label: 'Solicitado' },
  { value: '2', label: 'Entregue' },
  { value: '3', label: 'Colet Recusada' },
  { value: '4', label: 'Inativo' },
  { value: '5', label: 'Coleta Gerada' },
  { value: '6', label: 'Entrega Gerada' },
  { value: '7', label: 'Entrega Solicitada' },
  { value: '8', label: 'Entrega Recusada' },
  { value: '9', label: 'Coleta Processada' },
  { value: '10', label: 'Entrega Processada' },
  { value: '11', label: 'Coleta Finalizada' }
]

export const TABLE_HEADERS = {
  ['qt_unidades']: 'Qtd. Unidades',
  ['qt_pedidos']: 'Qtd. Pedidos',
  ['groupNumber']: 'Encomenda',
  ['salesOrderCreatedOn']: 'Dt. Pedido de Compra',
  ['expectedDate']: 'Prev. Entrega',
  ['situacao']: 'Situação',
  ['itemQuantity']: 'Total itens',
  ['cityState']: 'Cidade/UF',
  ['street']: 'Endereço de entrega'
}

export const ENCOMENDAS_TABLE_HEADERS = {
  ['employee']: 'Colaborador',
  ['cpf']: 'CPF',
  ['branchName']: 'Unidade',
  ['orderNumber']: 'Nº Pedido',
  ['operatorCode']: 'Código do produto',
  ['product']: 'Produto',
  ['ticketType']: 'Tipo',
  ['card']: 'Nº Cartão'
}
