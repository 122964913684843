enum Constants {
  TOKEN_STORAGE_KEY = '.OnixV2PortalIntegration',
  AUTHORIZATION_CODE = 'sensediaAuthorizationCode',
  TOKEN_STORAGE_PEDIDOS_KEY = '.OnixV2PortalIntegrationPedidos',
  USER_STORAGE_KEY = 'userInfo',
  USER_LASTROUTER_KEY = 'lastRouter',
  ONIX_STORAGE_KEY = '.AspNet.ApplicationCookieOnixPortal',
  ONIX_SESSION_ID_KEY = 'ASP.NET_SessionId',
  BLIP_REF = 'blip-chat-container',
  USER_ONBOARDING = 'userOnboarding',
  CONTACT_TELVR = '(11) 4004-4938',
  CONTACT_OTERS = '0800 596 3838',
  ERROR_DATA = 'Erro ao processar os dados',
  PARENT_LOCATION = 'PARENT.EVENT.LOCATION',
  TYPE_PREFIX = 'THIRD-PARTY-APP',
  PARENT_PREFIX = 'PARENT.EVENT',
  iS_ONBOARDING_FINISHED = 'isOnboardingFinished',
  iS_ONBOARDING_FINISHED_CREATE_ORDER = 'isOnboardingFinishedCreateOrder',
  iS_ONBOARDING_FINISHED_ORDER_PENDING = 'isOnboardingFinishedOrderPending',
  iS_ONBOARDING_FINISHED_ORDER_REVIEW = 'isOnboardingFinishedOrderReview',
  APP_SISTEM = 'ABSCardVR'
}

export default Constants
