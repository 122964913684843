import { Dispatch, SetStateAction, useState, useEffect, useRef } from 'react'

import { FormikProps, useFormik } from 'formik'

import useLocalStorage from '@/hooks/common/use-local-storage'
import { useGetBranchesData } from '@/hooks/queries/use-get-branch-data'
import { useObterConfiguracoesDoCreditoCerto } from '@/hooks/queries/use-get-configuracoes-credito-certo'
import { useGetContratos } from '@/hooks/queries/use-get-contratos'
import { useGetPedidos } from '@/hooks/queries/use-get-pedidos'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IConfiguracaoCreditoCertoList } from '@/interfaces/configuracaoCreditoCerto'
import { IContratoList } from '@/interfaces/contrato'
import { IPedidoItem } from '@/interfaces/pedidos'
import { createContext } from '@/utils/create-context'

interface IPedidosContextProps {
  pedidosList: IPedidoItem | undefined
  setFilteredData: Dispatch<SetStateAction<any>>
  filteredData: any
  isLoadingPedidos: boolean
  isFetchingPedidos: boolean
  setPage: Dispatch<SetStateAction<number>>
  totalPages: number
  setSearch: Dispatch<SetStateAction<string>>
  search: string
  formik: FormikProps<IFormProps>
  pageSize: number
  setPageSize: Dispatch<SetStateAction<number>>
  handleSelectedBranch: (selectedOption: any[]) => void
  handleClearForm: () => void
  formRef: React.RefObject<HTMLFormElement>
  contratos: IContratoList
  isLoadingContratos: boolean
  configuracoesCreditoCerto: IConfiguracaoCreditoCertoList
}
interface IFormProps {
  StartSalesOrderCreatedOn: string
  EndSalesOrderCreatedOn: string
  dataFim: string
  dataInicio: string
  DeliveryStatus: string
  orderNumber: string
  BranchCnpj: string
  idUnidades: string
  unidades: {
    value: string
    label: string
  }[]
  idPedidos: string
  situacoesPedidoVenda: { label: string; value: string }[]
  situacoesFinanceiras: { label: string; value: string }[]
  situacoesOperacionais: { label: string; value: string }[]
}

const [usePedidosContext, PedidosProvider] =
  createContext<IPedidosContextProps>()

const PedidosContext = ({ children }: JSX.Element | any) => {
  const { data: branches } = useGetBranchesData()
  const [filteredData, setFilteredData] = useState<any>()
  const [preferedPageSize, setPreferedPageSize] = useLocalStorage(
    'preferedPageSize',
    '10'
  )
  const [pageSize, setPageSize] = useState<number>(
    preferedPageSize ? Number(preferedPageSize) : 10
  )
  const [page, setPage] = useState<number>(10)
  const [search, setSearch] = useState('')
  const { data: userData } = useGetUserData()
  const [filterValues, setFilterValues] = useState({})

  useEffect(() => {
    page > 1 && setPage(1)
    setPreferedPageSize(pageSize)
  }, [pageSize])

  const defaultBranchId =
    branches?.filter(
      item => item.branchId === userData?.authenticatedBranchId
    )[0]?.branchId || ''

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      StartSalesOrderCreatedOn: '',
      EndSalesOrderCreatedOn: '',
      dataInicio: new Date(new Date().setMonth(new Date().getMonth() - 1))
        .toISOString()
        .split('T')[0],
      dataFim: new Date().toISOString().split('T')[0],
      DeliveryStatus: '',
      orderNumber: '',
      BranchCnpj: '',
      idUnidades: '',
      idUnidade: [defaultBranchId],
      unidades:
        branches
          ?.filter(item => item.branchId === userData?.authenticatedBranchId)
          .map(item => ({
            value: item.branchId,
            label: `${item.name}-${item.cnpj}`
          })) || ([] as any),
      idPedidos: '',
      unidadesSelecionadas: [defaultBranchId],
      situacoesPedidoVenda: [
        {
          label: 'Aprovado',
          value: 'Aprovado'
        },
        {
          label: 'Finalizado',
          value: 'Finalizado'
        },
        {
          label: 'Cancelado',
          value: 'Cancelado'
        }
      ],
      situacoesFinanceiras: [
        {
          label: 'Pago',
          value: 'Pago'
        },
        {
          label: 'Não Pago',
          value: 'NaoPago'
        },
        {
          label: 'Cancelado',
          value: 'Cancelado'
        },
        {
          label: 'Liberado',
          value: 'Liberado'
        }
      ],
      situacoesOperacionais: [
        {
          label: 'Não Confirmado',
          value: 'NaoConfirmado'
        },
        {
          label: 'Confirmado',
          value: 'Confirmado'
        },
        {
          label: 'Em Produção',
          value: 'EmProducao'
        },
        {
          label: 'Finalizado',
          value: 'Finalizado'
        }
      ]
    } as IFormProps,
    onSubmit: values => {
      const DTO = {
        idUnidades: values.unidades?.some(unit => unit.value === 'todos')
          ? branches?.map(branch => branch.branchId)
          : values.unidades?.map(item => item.value) || [],
        numeroPedido: values.idPedidos.length ? values.idPedidos : null,
        dataInicio: values.dataInicio,
        dataFim: values.dataFim,
        situacoesPedidoVenda: values.situacoesPedidoVenda.map(
          item => item.value
        ),
        situacoesFinanceiras: values.situacoesFinanceiras.map(
          item => item.value
        ),
        situacoesOperacionais: values.situacoesOperacionais.map(
          item => item.value
        )
      }
      setFilterValues(DTO)
    }
  })
  const startIndex = (page - 1) * pageSize

  const {
    data: pedidosList,
    isLoading: isLoadingPedidos,
    isFetching: isFetchingPedidos
  } = useGetPedidos({
    params: {
      idEmpregador: userData?.authenticatedAccountId || '',
      inicio: startIndex,
      comprimento: pageSize,
      campo: 'CriadoEm',
      direcao: 'desc',
      procurar: search,
      idUnidades: [defaultBranchId],
      ...filterValues,
      situacoesPedidoVenda: formik.values.situacoesPedidoVenda?.map(
        item => item.value
      ),
      situacoesFinanceiras: formik.values.situacoesFinanceiras?.map(
        item => item.value
      ),
      situacoesOperacionais: formik.values.situacoesOperacionais?.map(
        item => item.value
      ),
      dataInicio: formik.values.dataInicio + 'T00:00:00',
      dataFim: formik.values.dataFim + 'T23:59:59'
    }
  })

  const totalPages = Math.ceil(
    (pedidosList?.conteudo?.totalRegistros ?? 0) / pageSize
  )
  useEffect(() => setFilteredData(pedidosList), [])

  const handleSelectedBranch = async (selectedOption: any) => {
    if (selectedOption?.some((option: any) => option.value === 'todos')) {
      const allBranchIds = branches?.map((branch: any) => ({
        value: branch.branchId,
        label: `${branch.name}-${branch.cnpj}`
      }))

      formik.setFieldValue('unidades', allBranchIds)

      formik.setFieldValue('unidades', [{ value: 'todos', label: 'Todas' }])
    } else {
      formik.setFieldValue('unidades', selectedOption)
      formik.setFieldValue('unidades', selectedOption)
    }
  }

  const formRef = useRef<HTMLFormElement>(null)

  const handleClearForm = () => {
    formik.resetForm({
      values: {} as any
    })
    formik.submitForm()
    formRef.current?.reset()
  }

  const { data: contratos, isLoading: isLoadingContratos } = useGetContratos({
    params: {
      idEmpregador: userData?.authenticatedAccountId || '',
      idUnidades: userData?.branches.map(b => b.branchId)
    }
  })

  const { data: configuracoesCreditoCerto } =
    useObterConfiguracoesDoCreditoCerto({
      params: {
        idEmpregador: userData?.authenticatedAccountId || '',
        IdsUnidades: userData?.branches.map(b => b.branchId)
      }
    })

  return (
    <PedidosProvider
      value={{
        setPageSize,
        pedidosList,
        filteredData,
        setFilteredData,
        isLoadingPedidos,
        isFetchingPedidos,
        search,
        setSearch,
        setPage,
        totalPages,
        formik,
        pageSize,
        handleSelectedBranch,
        handleClearForm,
        formRef,
        contratos,
        isLoadingContratos,
        configuracoesCreditoCerto
      }}
    >
      {children}
    </PedidosProvider>
  )
}

export { PedidosContext, usePedidosContext }
