export function formatDateBrazilianString(dateString: string): string {
  const date = new Date(dateString)
  const formatDate = new Intl.DateTimeFormat('pt-br', {
    timeZone: 'America/Sao_Paulo',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).format(date)

  return formatDate
}
