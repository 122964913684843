import { routeNames } from '@/configs/routePaths'
import { ISearchItems } from '@/interfaces/common'

const isAllowedV2Pedidos = localStorage.getItem('isAllowedV2') === 'true'

export const LIST_OPTIONS: ISearchItems[] = [
  {
    keywords: 'Acompanhar Pedidos',
    uri: isAllowedV2Pedidos ? `/${routeNames.pedidos}` : routeNames.pedidosOld,
    title: 'Acompanhar Pedidos meus consultar'
  },
  {
    keywords: 'Resumo de Pedido',
    uri: isAllowedV2Pedidos ? `/${routeNames.pedidos}` : routeNames.pedidosOld,
    title: 'Acompanhar Pedidos'
  },
  {
    keywords: 'Exportar Pedido',
    uri: '/DemandsV3/Index',
    title: 'Pendências de  Pedidos'
  },
  {
    keywords: 'Detalhes de Pedido',
    uri: isAllowedV2Pedidos ? `/${routeNames.pedidos}` : routeNames.pedidosOld,
    title: 'Acompanhar Pedidos'
  },
  {
    keywords: 'Criar Pedido',
    uri: isAllowedV2Pedidos
      ? `/${routeNames.pedidos}/${routeNames.criarPedidos}`
      : `${routeNames.pedidosOld}?RedirectToTab=createDemandsTab`,
    title: 'Criar Pedidos transporte'
  },
  {
    keywords: 'Conta Corrente',
    uri: isAllowedV2Pedidos
      ? `/${routeNames.contaCorrente}`
      : `${routeNames.pedidosOld}?RedirectToTab=checkingAccountTab`,
    title: 'Conta Corrente Lançamento débito crédito'
  },
  {
    keywords: 'Devoluções',
    uri: isAllowedV2Pedidos
      ? `/${routeNames.pedidos}/${routeNames.devolucoes}`
      : `${routeNames.pedidosOld}?RedirectToTab=devolutionTab`,
    title: 'Devoluções fatura beneficiario reprocessar credito descontar'
  },
  {
    keywords: 'Crédito Certo',
    uri: isAllowedV2Pedidos
      ? `/${routeNames.contaCorrente}`
      : `${routeNames.pedidosOld}?RedirectToTab=balanceTab`,
    title: 'Devoluções fatura beneficiario reprocessar credito descontar'
  },
  {
    keywords: 'Configurações de Crédito Certo',
    uri: '/DemandsV3/Index?RedirectToTab=balanceTab',
    title: 'Crédito Certo escala trabalho'
  },
  {
    keywords: 'Importar Arquivo',
    uri: '/ToolboxV3/Index?RedirectToTab=DemandImportTab',
    title: 'Importar Arquivo'
  },
  {
    keywords: 'Exportar Arquivo',
    uri: '/ToolboxV3/Index?RedirectToTab=DemandsExportTab',
    title: 'Exportar Arquivo'
  },
  {
    keywords: 'De/Para Condução',
    uri: '/ToolboxV3/Index',
    title: 'Importar Arquivo'
  },
  {
    keywords: 'Histórico Arquivos Enviados',
    uri: '/ToolboxV3/Index',
    title: 'Importar Arquivo'
  },
  {
    keywords: 'Criar Solicitação',
    uri: '/Services/Index?RedirectToTab=DemandsExportTab',
    title: 'Criação solicitação fazer reclamação suporte ao site dúvidas'
  },
  {
    keywords: 'Acompanhar Solicitação',
    uri: '/Services/Index?RedirectToTab=mySolicitationsTab',
    title: 'Minhas Solicitações'
  },
  {
    keywords: 'Importar Solicitação',
    uri: '/Services/Index?RedirectToTab=importationTab',
    title: 'Importação'
  },
  {
    keywords: 'Arquivos de Solicitações Importadas',
    uri: '/Services/Index?RedirectToTab=importedFilesTab',
    title: 'Arquivos de Solicitações Importadas'
  },
  {
    keywords: 'Nova Melhor Rota Unitária',
    uri: '/RoutingRequest/Index?isVrPortal=True',
    title: 'Nova Melhor Rota Unitária'
  },
  {
    keywords: 'Melhor Rota por Importação',
    uri: '/RoutingRequest/Index?isVrPortal=True',
    title: 'Melhor Rota por Importação'
  },
  {
    keywords: 'Minhas Roteirizações',
    uri: '/RoutingRequest/Index?isVrPortal=True',
    title: 'Minhas Roteirizações'
  },
  {
    keywords: 'Relatório de Centro de Custo',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Encomendas',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Crédito Certo',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Lista de Crédito',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Pedidos de Venda',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório Valor Importado x Valor Pedido de Venda',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório Gerencial',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Prévia de Compra',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Cadastro'
  },
  {
    keywords: 'Relatório Financeiro',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Financeiro'
  },
  {
    keywords: 'Imprimir Boletos em Aberto',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Financeiro'
  },
  {
    keywords: 'Imprimir Notas Fiscais do Grupo',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Financeiro'
  },
  {
    keywords: 'Imprimir Recibos',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Financeiro'
  }
]
