import EditarEncomendaContainer from '@/containers/encomendas/editar-encomenda.container'
import { EditarEncomendaContext } from '@/contexts/editar-encomenda.context'

const EncomendasEdit = () => {
  return (
    <div className='w-full bg-white rounded-md p-5 md:-mt-8'>
      <EditarEncomendaContext>
        <EditarEncomendaContainer />
      </EditarEncomendaContext>
    </div>
  )
}

export default EncomendasEdit
