import { SetStateAction, useMemo, useState } from 'react'

import debounce from 'debounce'
import { useParams } from 'react-router-dom'

import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { useDetalhePedidoContext } from '@/contexts/detalhe-pedido.context'
import { useGetRepasses } from '@/hooks/queries/use-get-pedidos'
import { TABLE_HEADERS_REPASSES_PEDIDO } from '@/pages/Pedidos/constants'
import { roundMonetaryValue } from '@/utils'

const subtipoTaxa: { [status: string]: string } = {
  ['RepassePorPercentual']: 'Percentual',
  ['RepassePorColaborador']: 'Por Colaborador'
}

const DetailRepassesPedido = () => {
  const { pedidoData } = useDetalhePedidoContext()
  const { id } = useParams()
  const [search, setSearch] = useState('')
  const repassesPageSize = 10
  const [repassesPage, setRepassesPage] = useState<number>(1)
  const repassesInicio = (repassesPage - 1) * repassesPageSize
  const { data: repassesList, isIdle } = useGetRepasses({
    params: {
      pedidoId: id as string,
      inicio: repassesInicio,
      comprimento: repassesPageSize,
      procurar: search,
      tipo: 'Repasse'
    }
  })

  const mappedRepasses = useMemo(() => {
    return repassesList?.conteudo?.resultados?.map((item: any) => {
      return {
        operadora: item.nomeOperadora,
        tipo: subtipoTaxa[item.subtipoTaxa],
        descricao: item.descricao,
        valorUnitario:
          item.subtipoTaxa === 'RepassePorPercentual'
            ? '-'
            : item.valor.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }),
        quantidade: item.quantidade,
        valorTotal: (() => {
          return roundMonetaryValue(item.valorTotal).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          })
        })(),
        valorTotalRaw: item.valorTotal
      }
    })
  }, [repassesList])

  const repassesTotalPages = Math.ceil(
    (repassesList?.conteudo?.quantidadeDeItems ?? 0) / repassesPageSize
  )

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  const valorTotal =
    pedidoData != null ? pedidoData[0]?.repasses ?? 'R$ 0,00' : 'R$ 0,00'

  return (
    <div className='overflow-x-auto'>
      <SearchTableItem
        isAddItem={false}
        isSearchInputEnabled={true}
        setFilterText={e => handleSearch(e)}
      />
      <TableItem
        isActions={false}
        isRowClicable={false}
        data={mappedRepasses || []}
        headers={TABLE_HEADERS_REPASSES_PEDIDO}
        deleteAction={() => void 0}
        hasCustomActions={false}
        idKey='pedidoId'
        isIdle={isIdle}
      />
      {valorTotal !== 'R$ 0,00' && (
        <div className='w-full pt-5 flex items-center justify-end'>
          <span className='font-bold text-xl'>
            Valor total geral:{' '}
            <span className='text-quaternary'>{valorTotal}</span>
          </span>
        </div>
      )}
      <PaginationItem
        totalItems={repassesList?.conteudo?.quantidadeDeItems as number}
        totalPages={repassesTotalPages}
        pageSize={repassesPageSize}
        onPageChange={(pageParam: number) => setRepassesPage(pageParam)}
      />
    </div>
  )
}

export default DetailRepassesPedido
