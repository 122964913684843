import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Alert } from 'flowbite-react'

import useSessionStorage from '@/hooks/common/use-session-storage'

const LogisticAlerts = () => {
  const [alert, setAlert] = useSessionStorage<string>('isDismissed', '')
  return (
    <div className='w-full col-span-12 mb-3'>
      <Alert color={'warning'}>
        <div className='flex justify-center'>
          <InformationCircleIcon className='w-5 h-5 mr-2' />
          <span>
            {' '}
            O detalhamento da entrega fica disponível somente após a confirmação
            da coleta na operadora
          </span>
        </div>
      </Alert>
      {alert === '' && (
        <Alert
          color={'warning'}
          className='mt-3'
          onDismiss={() => setAlert('true')}
        >
          <div className='flex justify-center'>
            <InformationCircleIcon className='w-5 h-5 mr-2' />
            <span>
              As entregas da <b>RioCard</b> são realizadas pela própria
              operadora, para saber o status, pedimos que entre em contato com a
              nossa equipe de atendimento.
            </span>
          </div>
        </Alert>
      )}
    </div>
  )
}

export default LogisticAlerts
