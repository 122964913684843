import { useState } from 'react'

import classNames from 'classnames'

interface IPropsTimeLineItem {
  id: number
  title: string
  date: string
  icon: string
  finished: boolean
  description: any
  deliveryFailed?: boolean
}
const TimeLineItem = (item: IPropsTimeLineItem) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <div
      className={classNames(
        'relative lg:col-span-2 col-span-12 flex justify-start items-center flex-col gap-1'
      )}
    >
      <div
        className={classNames(
          item.finished
            ? 'bg-cyan-700'
            : item.deliveryFailed
            ? 'bg-red-700'
            : 'bg-gray-400',
          'rounded-full flex justify-center items-center p-4 z-40 w-24 h-24'
        )}
      >
        <img
          src={item.icon}
          alt='Status icon'
          className='text-white w-12 invert'
        />
      </div>
      <p className='font-semibold text-center text-sm'>
        {item.title === 'Entrega concluída' && item.deliveryFailed
          ? 'Falha na Entrega'
          : item.title}
      </p>
      <span className='text-xs text-gray-600 text-center'>
        {!item.finished && !item.deliveryFailed && 'Previsto para: '}
        {item.finished
          ? item.date
          : item.deliveryFailed
          ? ''
          : item.date.slice(0, 10)}
      </span>
      {item?.description && !item.deliveryFailed && (
        <span
          className={classNames(
            !expanded && 'line-clamp-3',
            'text-xs text-center bg-green-100 text-primary-dark rounded-xl px-2 py-1 border-primary-dark'
          )}
        >
          {item?.description}
        </span>
      )}
      {item?.description &&
        item?.description.length > 41 &&
        !expanded &&
        !item.deliveryFailed && (
          <span
            className='underline text-xs text-gray-400 cursor-pointer'
            onClick={() => setExpanded(value => !value)}
          >
            Leia mais
          </span>
        )}

      <hr
        className={classNames(
          item.finished
            ? 'bg-cyan-700'
            : item.deliveryFailed
            ? 'bg-red-700'
            : 'bg-gray-400',
          'h-1  w-full rounded-full absolute top-12 right-24 hidden lg:block'
        )}
      />
    </div>
  )
}

export default TimeLineItem
