import { useEffect, useState } from 'react'

import { Tabs } from 'flowbite-react'
import { useLocation, useNavigate } from 'react-router-dom'

import { TAB_THEME } from '../detalhe/tab-theme'
import AcompanharPedidosContainer from '@/containers/pedidos/acompanhar-pedidos.container'
import PedidosPendentesContainer from '@/containers/pedidos/pedidos-pendentes.container'
import { PedidosPendentesContext } from '@/contexts/pedidos-pendentes.context'
import { PedidosContext } from '@/contexts/pedidos.context'

import 'intro.js/introjs.css'

const AcompanharPedidosView = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    const activeTabLabel = document.querySelectorAll('[type="button"]')
    activeTabLabel.forEach(tab => {
      tab.addEventListener('click', () => {
        tab.textContent === 'Pedidos Aprovados' &&
          navigate('/pedidos/aprovados')
        tab.textContent === 'Pedidos Pendentes' &&
          navigate('/pedidos/pendentes')
      })
    })
    location.pathname === '/pedidos/aprovados'
      ? setActiveTab(0)
      : setActiveTab(1)
    location.pathname === '/pedidos' && setActiveTab(0)
  }, [location])

  return (
    <div className='w-full bg-white rounded-md p-5 md:-mt-8'>
      <Tabs
        onActiveTabChange={tab => setActiveTab(tab)}
        aria-label='Tabs with underline'
        variant='default'
        theme={TAB_THEME}
      >
        <Tabs.Item
          active={location.pathname === '/pedidos/aprovados'}
          title='Pedidos Aprovados'
          id='aprovados'
          className='step-tab-aprovados'
        >
          {activeTab === 0 && (
            <PedidosContext>
              <AcompanharPedidosContainer />
            </PedidosContext>
          )}
        </Tabs.Item>
        <Tabs.Item
          active={location.pathname === '/pedidos/pendentes'}
          title='Pedidos Pendentes'
          id='pendentes'
          className='pedidos-pendentes-aprovacao'
        >
          {activeTab === 1 && (
            <PedidosPendentesContext>
              <PedidosPendentesContainer />
            </PedidosPendentesContext>
          )}
        </Tabs.Item>
      </Tabs>
    </div>
  )
}

export default AcompanharPedidosView
